import { attachDynamicEvent } from '../../../common/helpers/dom'
import { getBaseCartPath, showLoading, hideLoading } from './common'
import { processResponse } from '../../behaviors/remote/process_response'

// Can be moved to discount component controller and simplified once we get rid of 2 cart implementations
function performCouponAction(action, paramName, paramValue) {
  showLoading()
  let form = document.querySelector('form.cart-checkout-details')
  let body = new URLSearchParams(new FormData(form))
  body.append(paramName, paramValue)

  fetch(`${getBaseCartPath()}/${action}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body,
  })
    .then((response) => response.json())
    .then((json) => processResponse(json))
    .then(hideLoading)
}
export function applyCoupon(paramName, paramValue) {
  performCouponAction('apply_coupon', paramName, paramValue)
}
export function removeCoupon(paramName, paramValue) {
  performCouponAction('remove_coupon', paramName, paramValue)
}
